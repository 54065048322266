import {
  Box,
  Button,
  ButtonGroup,
  ChakraProvider,
  Heading,
  IconButton,
  Image,
  Link,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import "dayjs/locale/de";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import DisplayDoor from "./components/doors/display-door";
import Doors from "./components/doors/index";
import erster from "./images/erster.png";
import zweiter from "./images/zweiter.png";
import dritter from "./images/dritter.png";
import vierter from "./images/vierter.png";
import fuenfter from "./images/fuenfter.png";
import sechster from "./images/sechster.png";
import siebter from "./images/siebter.png";
import achter from "./images/achter.png";
import neunter from "./images/neunter.png";
import zehnter from "./images/zehnter.png";
import elfter from "./images/elfter.png";
import zwoelfter from "./images/zwoelfter.png";
import dreizehnter from "./images/dreizehnter.png";
import vierzehnter from "./images/vierzehnter.png";
import fuenfzehnter from "./images/fuenfzehnter.png";
import sechszehnter from "./images/sechszehnter.png";
import siebzehnter from "./images/siebzehnter.png";
import achtzehnter from "./images/achtzehnter.png";
import neunzehnter from "./images/neunzehnter.png";
import zwanzigster from "./images/zwanzigster.png";
import einundzwanzigster from "./images/einundzwanzigster.png";
import zweiundzwanzigster from "./images/zweiundzwanzigster.png";
import dreiundzwanzigster from "./images/dreiundzwanzigster.png";
import vierundzwanzigster from "./images/vierundzwanzigster.png";
dayjs.locale("de");

const SocialLinks = ({ colorScheme = "whiteAlpha" }) => (
  <List>
    <ListItem>
      <Button
        colorScheme={colorScheme}
        as={Link}
        href="https://twitter.com/figuchan?lang=de"
        variant="link"
        leftIcon={<FontAwesomeIcon icon={faTwitter} />}
      >
        Twitter
      </Button>
    </ListItem>
    <ListItem>
      <Button
        colorScheme={colorScheme}
        as={Link}
        href="https://www.facebook.com/Figuya/"
        variant="link"
        leftIcon={<FontAwesomeIcon icon={faFacebook} />}
      >
        Facebook
      </Button>
    </ListItem>
    <ListItem>
      <Button
        colorScheme={colorScheme}
        as={Link}
        href="https://www.instagram.com/figuya/"
        variant="link"
        leftIcon={<FontAwesomeIcon icon={faInstagram} />}
      >
        Instagram
      </Button>
    </ListItem>
  </List>
);
const SocialButtons = () => (
  <ButtonGroup>
    <IconButton
      as={Link}
      href="https://twitter.com/figuchan?lang=de"
      colorScheme="whiteAlpha"
      icon={<FontAwesomeIcon icon={faTwitter} />}
    />
    <IconButton
      as={Link}
      href="https://www.facebook.com/Figuya/"
      colorScheme="whiteAlpha"
      icon={<FontAwesomeIcon icon={faFacebook} />}
    />
    <IconButton
      as={Link}
      href="https://www.instagram.com/figuya/"
      colorScheme="whiteAlpha"
      icon={<FontAwesomeIcon icon={faInstagram} />}
    />
  </ButtonGroup>
);

const App = () => {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Box top="30px" right="30px" zIndex="3" position="fixed">
          <SocialButtons />
        </Box>
        <Doors />
        <DisplayDoor bg="#4D1A13" day={1} pb={0}>
          <Heading size="md">Kingdom Hearts Fanpaket</Heading>

          <Text>
            Es ist bereits wieder soweit! Unser Adventskalender feiert seinen
            Auftakt mit einem großartigen Gewinnspiel. Hinter unserem heutigen
            Türchen erwarten euch drei grandiose Kingdom Hearts Fanpakete, mit
            vielen tollen Merchandise-Artikeln zu dem Game. Dabei verlosen wir
            je eines der drei Fanpakete auf unserem Social Media Kanal{" "}
            <Link href="https://twitter.com/figuchan?lang=de">Twitter</Link> ,{" "}
            <Link href="https://www.facebook.com/Figuya/">Facebook</Link> und{" "}
            <Link href="https://www.instagram.com/figuya/">Instagram</Link>.
            Vorbeischauen und mitmachen lohnt sich also!
          </Text>
          <Image src={erster} />
        </DisplayDoor>
        <DisplayDoor day={2} bg="#54130A" pb={0}>
          <Text>
            Heute bestellen und 7% sparen mit dem Gutscheincode{" "}
            <strong>STERNSTUNDEN</strong>.
          </Text>
          <Text>
            Viel Spass beim stöbern auf{" "}
            <Link href="https://figuya.com/de">figuya.com</Link>.
          </Text>
          <Image src={zweiter} />
        </DisplayDoor>
        <DisplayDoor day={3}>
          <Heading size="md">Figuya Fanart Wettbewerb</Heading>
          <Text>
            Unsere Community ist voll mit Zeichnern, Illustratoren und anderen
            beeindruckenden Künstlern. Mit dem 3. Türchen wollen wir kreativ in
            die Weihnachtszeit starten. Für alle die sich bereits angesprochen
            fühlen, dieser Wettbewerb ist genau für euch!
          </Text>
          <Text>
            Wir veranstalten mit euch zusammen einen Fanart Wettbewerb. Das
            Thema soll folgendes sein: Der Frühling!
          </Text>
          <Text>
            Malt, zeichnet, designt. Lasst eurer Kreativität freien Lauf.
            Einzige Voraussetzung für die Bilder ist, dass unser Maskottchen
            Figu-Chan darauf enthalten sein soll. Zeichnet Figu-chan also zum
            Beispiel in einem Frühlingsumfeld zwischen den aufblühenden
            Kirschblütenbäumen oder vielleicht schmilzt gerade der Schnee und
            erste Knospen zeigen uns, dass nun der Frühling erst so richtig
            beginnt.
          </Text>
          <Text>
            Schickt uns eure Einsendungen bis zum 22.12.21 an
            (gewinnspiele@figuya.de)
          </Text>
          <Text>
            Die Gewinner werden Online benachrichtigt. Jeder der unser etwas
            einsendet erklärt sich dazu bereit, dass wir die Artworks auch
            Öffentlich präsentieren dürfen.
          </Text>
          <Text>Natürlich gibt es auch festliche Gewinne abzustauben.</Text>
          <List as="ol">
            <ListItem>
              <Heading size="sm" as="div">
                1. Platz:
              </Heading>
              <Link href="https://figuya.com/de/produkte/amaterasu-1-1-bueste-okami-first-4-figures-8783-de">
                Amaterasu - 1/1 Büste - Okami - First 4 Figures
              </Link>
            </ListItem>
            <ListItem>
              <Heading size="sm" as="div">
                2. und 3. Platz:
              </Heading>
              <Link href="https://figuya.com/de/produkte/eva-02-eva-2020-style-metal-build-bandai-spirits-21742-de">
                EVA-02 - EVA 2020 Style - Metal Build - Bandai Spirits
              </Link>
            </ListItem>
            <ListItem>
              <Heading size="sm" as="div">
                3. bis 6. Platz:
              </Heading>
              Jeweils ein Genshin Impact Fanpaket
            </ListItem>
          </List>
          <Text>
            Darüber hinaus werden wir einige der Motive die uns am Besten
            gefallen zu Flyern bearbeiten und zukünftigen Sendungen beilegen.
            Viel Glück!
          </Text>
          <Image src={dritter} />
        </DisplayDoor>
        <DisplayDoor day={4} pb={0}>
          <Text>
            Auch heute erwartet euch wieder eine tolle Überraschung! Hinter
            unserem 4. Türchen verbirgt sich nämlich eine großartige Store
            Aktion für euch. Heute könnt ihr dabei in unserem Store in Berlin
            Mitte auf all eure Einkäufe ganze 7 % sparen, ausgenommen davon sind
            lediglich Artikel mit Buchpreisbindung. So macht der
            Weihnachtseinkauf doch noch einmal doppelt Spaß! Wir freuen uns euch
            bei uns begrüßen zu dürfen.
          </Text>
          <Image src={vierter} />
        </DisplayDoor>
        <DisplayDoor day={5}>
          <Text>
            Hinter unserem heutigen Türchen erwartet euch ein tolles
            Gewinnspiel! Wir verlosen in Kooperation mit Tokyopop drei
            großartige Manga-Pakete für euch. Mit dabei sind folgende Titel:
          </Text>
          <List>
            <ListItem>Yona Prinzessin der Morgendämmerung Band 1</ListItem>
            <ListItem>Konosuba Light Novel</ListItem>
            <ListItem>Fire Force Band 1</ListItem>
            <ListItem>Shaman King Band 1</ListItem>
          </List>
          <Text>
            Wir verlosen je eines der 3 Pakete auf unserem Social Media Kanal
          </Text>
          <SocialLinks />
          <Text>
            Vorbeischauen lohnt sich also auf jedenfall! Wir freuen uns auf eure
            Teilnahme.
          </Text>
          <Image src={fuenfter} />
        </DisplayDoor>
        <DisplayDoor day={6} pb={0}>
          <Heading size="md">Nikolaus Sale</Heading>
          <Text>
            Hinter unserem heutigen Türchen verbirgt sich ein großartiger Sale
            zum Nikolaus! Mit dabei sind 200 Produkte, die ihr euch für eure
            Fan-Sammlungen sichern könnt. Schaut gerne vorbei und werft einen
            Blick auf die zahlreichen Produkte, die ihr nur heute zu einem
            unglaublich guten Preis ergattern könnt und spart so noch einmal
            richtig für euren Weihnachtseinkauf. Wir wünschen euch ganz viel
            Spaß beim durchstöbern, entdecken und shoppen!
          </Text>
          <Button
            colorScheme="red"
            as="a"
            href="https://figuya.com/de/sales/nikolaus-sale-2021"
          >
            Nikolaus Sale
          </Button>
          <Image src={sechster} />
        </DisplayDoor>
        <DisplayDoor day={7} pb={0}>
          <Heading size="md">Nendoroid Gewinnspiel</Heading>
          <Text>
            Das heutige Türchen hält wieder so einiges für euch bereit! Heute
            gibt es nämlich wieder ein großartiges Gewinnspiel und dabei könnt
            ihr auf jeder Social Media Seite etwas grandioses ergattern.
          </Text>
          <Text>
            Auf instagram erwartet euch eine coole{" "}
            <Link href="https://figuya.com/de/produkte/nendoroid-1514-sesshomaru-28192-de">
              Nendoroid von Sesshomaru aus inuyasha zusammen mit einem Dress Up:
              Suit Set
            </Link>{" "}
            . Auf Facebook habt ihr die Möglichkeit eine Nendoroid des
            Intelligenten Klassensprechers{" "}
            <Link href="https://figuya.com/de/produkte/nendoroid-1428-tenya-iida-21868-de">
              Tenya Ida aus My Hero Academia, zusammen mit einem Dress Up: Suit
              Set
            </Link>
            , zu gewinnen und auf Twitter könnt ihr die bezaubernde{" "}
            <Link href="https://figuya.com/de/produkte/nendoroid-1429-triss-merigold-21874-de">
              Triss Merigold aus dem Game Witcher und einem Dress up: Suit Set
            </Link>
            , mit etwas Glück für eure Sammlung erhalten . Wie ihr am
            Gewinnspiel teilnehmen könnt erfahrt ihr auf den jeweiligen
            Plattformen. Vorbeischauen und mitmachen lohnt sich also garantiert.
            Wir wünschen euch viel Glück!
          </Text>
          <Image src={siebter} />
        </DisplayDoor>
        <DisplayDoor day={8} pb={0}>
          <Heading size="md">Digimon Geschenke</Heading>
          <Text>
            Hinter unserem heutigen Türchen erwarten euch coole Digimon
            Geschenke! Zu eurer heutigen Bestellung bekommt ihr je 1x ein
            Digimon Booster sowie 1x eine Digimon Promokarten Collection
            kostenlos zu eurer Bestellung hinzu. Schreibt dazu einfach in eure
            Bestellnotiz das Wort “ULTRA-DIGITATION” und schon packen wir die
            coolen Sachen mit in euer Paket. Schaut also gleich mal auf unserer
            Website vorbei:
          </Text>
          <Button colorScheme="whiteAlpha" as="a" href="https://figuya.com/de">
            Figuya
          </Button>
          <Image src={achter} />
        </DisplayDoor>
        <DisplayDoor day={9} pb={0}>
          <Heading size="md">Ghibli Stream</Heading>
          <Text>
            Hinter unserem heutigen, 9.Türchen haben wir gleich zwei coole
            Sachen für euch vorbereitet! Heute um 17 Uhr könnt ihr uns live auf
            Twitch verfolgen und mit uns zusammen über die fantastischen Welten
            der Ghibli Filme reden. Das war aber noch nicht alles, denn ihr habt
            zudem die Möglichkeit im Stream auch etwas tolles zu Gewinnen.
            Schaut also gerne vorbei, wir freuen uns euch in unserem Stream
            begrüßen zu können.
          </Text>
          <Button
            colorScheme="whiteAlpha"
            as="a"
            href="https://figuya.com/de/taxons/ghibli"
          >
            Ghibli
          </Button>
          <Image src={neunter} />
        </DisplayDoor>
        <DisplayDoor day={10} pb={0}>
          <Text>
            Heute haben wir wieder eine großartige Aktion für euch vorbereitet!
            Ihr könnt euch heute mit dem Gutscheincode “Plätzchen” 7 % Rabatt
            auf euren Einkauf sichern. So macht das Weihnachtsshopping also noch
            einmal doppelten Spaß. Wir wünschen euch viel Freude beim Stöbern
            und Entdecken und natürlich auch beim Sparen.
          </Text>
          <Button colorScheme="whiteAlpha" as="a" href="https://figuya.com/de">
            Figuya
          </Button>
          <Image src={zehnter} />
        </DisplayDoor>
        <DisplayDoor day={11} pb={0}>
          <Text>
            Heute haben wir für euch, hinter unserem 11.Türchen, ein weiteres
            großartiges Gewinnspiel! In Kooperation mit Animoon könnt ihr auf
            unseren Social Media Seiten coole DVD’s und Blurays gewinnen. Mit
            dabei sind die folgenden Titel: 2x Bluray Vol.1 Strike the Witches,
            2x DVD Vol.1 Strike the Witches, 1x DVD Vol.1 Higurashi, 1x Bluray
            Vol.1 Higurashi, 1x DVD My Teen Romantic Comedy SNAFU, 1x Bluray My
            Teen Romantic Comedy SNAFU. Diese Gewinne könnt ihr heute verteilt
            auf unseren drei Kanälen Twitter, Instagram und Facebook ergattern.
            Vorbeischauen und mitmachen lohnt sich also. Wir wünschen euch schon
            einmal viel Glück!
          </Text>
          <SocialLinks />
          <Button colorScheme="whiteAlpha" as="a" href="https://figuya.com/de">
            Figuya
          </Button>
          <Image src={elfter} />
        </DisplayDoor>
        <DisplayDoor day={12} pb={0}>
          <Heading>Death Note Fanpaket</Heading>
          <Text>
            Hinter unserem heutigen Türchen gibt es wieder etwas großartiges zu
            gewinnen. In Zusammenarbeit mit Tokyopop haben wir insgesamt zwei
            Varianten an grandiosen Death Note Fanpaketen vorbereitet. Im 1.
            Paket erwartet euch eine coole Ryuk Figur, ein Ryuk
            Schlüsselanhänger und ein Death Note Notizbuch. Im 2. Paket sind für
            euch eine coole Figur von Ryuk in der Glow in the Dark Version, ein
            Ryuk Schlüsselanhänger und eine Light Novel L Change the World
            enthalten. Ihr könnt sowohl Paket 1 als auch Paket 2 je einmal auf
            unseren drei Social Media Kanälen Twitter Instagram und Facebook
            gewinnen. Schaut also gerne mal rein und versucht euer Glück!
          </Text>
          <Button colorScheme="whiteAlpha" as="a" href="https://figuya.com/de">
            Figuya
          </Button>
          <Image src={zwoelfter} />
        </DisplayDoor>
        <DisplayDoor color="black" day={13} pb={0}>
          <Heading size="md">Süßigkeiten Sale</Heading>
          <Text>
            Heute haben wir für euch einen candyhaften Rabatt vorbereitet. Dies
            bedeutet, dass ihr so einiges sparen könnt auf viele unserer
            Süßigkeiten und auf unsere Süßigkeiten Luckybags. Schaut also gerne
            einmal vorbei und sichert euch die tollen japanischen Leckereien,
            die wir für euch im Angebot haben. Bitte beachtet, dass die
            Süßigkeiten und die Süßigkeiten Luckybag im Bild nur ein Beispiel
            darstellen. Hier geht es zu den vielen japanischen köstlichkeiten:
          </Text>
          <Button
            colorScheme="red"
            as="a"
            href="https://figuya.com/de/sales/suessigkeiten-sale"
          >
            Süßigkeiten Sale
          </Button>
          <Image src={dreizehnter} />
        </DisplayDoor>
        <DisplayDoor color="black" day={14} pb={0}>
          <Heading size="md">Sailor Moon Fanpaket</Heading>
          <Text>
            Hinter unserem heutigen Türchen erwartet euch ein sensationelles und
            magisches Fanpaket. In Zusammenarbeit mit Egmont verlosen wir drei
            großartige Sailor Moon Fanpakete, die viele grandiose Produkte rund
            um das Magical Girl Epos Sailor Moon enthalten. Zu den Produkten,
            die euch im Paket erwarten, gehören unter anderem das erste Band zu
            Pretty Guardian Sailor Moon in der Eternal Edition, eine Chibiusa
            Q-Posket Figur, eine Sailor Moon Tasse sowie weitere coole Sailor
            Moon Kosmetikprodukte. Je eines der drei Pakete könnt ihr auf
            unseren Social Media Kanälen Twitter, Facebook und Instagram
            gewinnen. Alle weiteren Teilnahmevoraussetzungen findet ihr auch auf
            dem jeweiligen Kanal. Wir freuen uns auf eure Teilnahme und wünschen
            euch viel Glück!
          </Text>
          <SocialLinks colorScheme="blackAlpha" />
          <Button
            colorScheme="red"
            as="a"
            href="https://figuya.com/de/taxons/sailor-moon"
          >
            Sailor Moon bei Figuya
          </Button>
          <Image src={vierzehnter} />
        </DisplayDoor>
        <DisplayDoor day={15} pb={0}>
          <Heading size="md">Gutschein</Heading>
          <Text>
            Hinter unserem 15. Adventskalendertürchen verbirgt sich für euch
            wieder eine tolle Aktion! Heute könnt ihr mit unserem Code
            “Schneemann” coole 7 % auf euren Einkauf bei uns im Onlineshop
            sparen. Die perfekte Gelegenheit also, sich noch einmal schnell die
            restlichen Geschenke zum Weihnachtsfest zu sichern oder sich einfach
            selbst noch einmal eine kleine Weihnachtsfreude zu machen. Wir
            wünschen euch ganz viel Spaß beim stöbern und entdecken der
            großartigen Produkte!
          </Text>
          <Button colorScheme="red" as="a" href="https://figuya.com/de">
            Figuya
          </Button>
          <Image src={fuenfzehnter} />
        </DisplayDoor>
        <DisplayDoor day={16} pb={0}>
          <Heading size="md">Geschenke Stream</Heading>
          <Text>
            Hinter unserem heutigen Türchen verbergen sich gleich zwei tolle
            Aktionen. Wir haben zum einen wieder einen coolen Live Stream auf
            Twitch für euch vorbereitet und zum anderen verlosen wir im Stream,
            jede Menge toller Preise. Schaut also gerne rein und plaudert mit
            uns gemeinsam über die Welt der Anime! Wir freuen uns euch bei uns
            im Live Stream begrüßen zu dürfen.
          </Text>
          <Button colorScheme="red" as="a" href="https://www.twitch.tv/figuya">
            Figuya auf Twitch
          </Button>
          <Image src={sechszehnter} />
        </DisplayDoor>
        <DisplayDoor day={17} pb={0}>
          <Heading size="md">Verlosung - Egmont Manga Paket</Heading>
          <Text>
            Heute verlosen wir in Zusammenarbeit mit Egmont drei großartige
            Manga-Pakete für euch. Mit dabei sind die folgenden Titel: Mila
            Superstar Band 1, Kaguya-sama: Love is War Band 1 und The
            Quintessential Quintuplets Band 1. Wir verlosen je eines der 3
            Pakete auf unserem Social Media Kanal Twitter , Instagram und
            Facebook . Vorbeischauen lohnt sich also auf jeden Fall! Wir freuen
            uns auf eure Teilnahme. Das Gewinnspiel läuft nur heute und wir
            bedanken uns nochmals herzlich bei Egmont für diese tollen Preise.
          </Text>
          <SocialLinks />
          <Image src={siebzehnter} />
        </DisplayDoor>
        <DisplayDoor day={18} pb={0}>
          <Heading size="md"> Store Aktion </Heading>
          <Text>
            Auch heute erwartet euch wieder eine tolle Überraschung! Hinter
            unserem 18. Türchen verbirgt sich nämlich eine großartige Store
            Aktion für euch. Heute könnt ihr dabei in unserem Store in Berlin
            Mitte auf all eure Einkäufe ganze 7 % sparen, ausgenommen davon sind
            lediglich Artikel mit Buchpreisbindung. So macht der
            Weihnachtseinkauf doch noch einmal doppelt Spaß! Wir freuen uns euch
            bei uns begrüßen zu dürfen.
          </Text>
          <Image src={achtzehnter} />
        </DisplayDoor>
        <DisplayDoor day={19} pb={0}>
          <Heading size="md"> Nanoblock Pakete </Heading>
          <Text>Heute verlosen wir insgesamt 6 Nanoblock Pakete</Text>
          <SocialLinks />
          <Image src={neunzehnter} />
        </DisplayDoor>
        <DisplayDoor day={20} pb={0}>
          <Heading size="md"> Gutscheincode</Heading>
          <Text>
            Hinter unserem 20. Adventskalendertürchen verbirgt sich für euch
            nochmal ein Rabattcode! Was könnte da passender sein als der Code
            “KURISUMASU PUREZENTO”, um bei eurem Einkauf nochmal 7% bei uns im
            Onlineshop figuya.com zu sparen. Die perfekte Gelegenheit also, sich
            noch einmal schnell die restlichen Geschenke zum Weihnachtsfest zu
            sichern oder sich einfach selbst noch einmal eine kleine
            Weihnachtsfreude zu machen. Wir wünschen euch ganz viel Spaß beim
            stöbern und entdecken der großartigen Produkte! Mit dabei sind
            Figuren, japanische Snacks, Mangas, Puzzle, Tassen und vieles mehr
            zu euren Lieblings-Anime.
          </Text>
          <SocialLinks />
          <Image src={zwanzigster} />
        </DisplayDoor>
        <DisplayDoor day={21} pb={0}>
          <Heading size="md">Kurisumasu Stream</Heading>
          <Text>
            Hinter unserem 21. Türchen verbirgt sich wieder ein toller Stream!
            Heute um 17 Uhr sind wir Live auf Twitch und freuen uns natürlich
            über euren Besuch. In gemütlicher Runde werden wir über Weihnachten
            sprechen und uns einige Fanarts vom Fanart-Wettbewerb anschauen,
            welcher sich hinter Türchen 3 verborgen hatte. Schaut gerne vorbei!
          </Text>
          <Button colorScheme="red" as="a" href="https://www.twitch.tv/figuya">
            Figuya auf Twitch
          </Button>
          <Image src={einundzwanzigster} />
        </DisplayDoor>
        <DisplayDoor day={22} pb={0}>
          <Heading size="md">Altravernt (Gewinnspiel Altraverse)</Heading>
          <Text>
            Hinter dem heutigen Türchen erwartet euch wieder ein tolles
            Gewinnspiel! Wir verlosen in Zusammenarbeit mit Altraverse
            großartige Pappaufsteller von Fullmetal Alchemist, Ride on King &
            Barakamon, die es so nicht im Handel zu kaufen gibt und legen selbst
            nochmal 3x ein Fullmetal Alchemist Artbook mit dazu. Weitere Infos,
            wie ihr Teilnehmen könnt, findet ihr natürlich auf unseren
            Social-Media-Kanälen Facebook , Instagram und Twitter . Mitmachen
            lohnt sich, schaut also am besten gleich vorbei! Wir wünschen euch
            viel Glück!
          </Text>
          <SocialLinks />
          <Image src={zweiundzwanzigster} />
        </DisplayDoor>
        <DisplayDoor day={23} pb={0}>
          <Heading size="md">Pokémon Fanpaket</Heading>
          <Text>
            Heute haben wir wieder ein tolles Gewinnspiel für euch vorbereitet!
            Ihr könnt auf unseren Social-Media-Kanälen Facebook (hier Link
            einfügen), Instagram (hier Link einfügen) und Twitter (hier Link
            einfügen) je eines dieser 3 tollen Pokémon Fanpakete gewinnen! Im
            Paket erwartet euch unter anderem ein süßes Pokémon Plüschtier,
            Sammelkarten und weitere tolle Produkte für eure Sammlung. Wie ihr
            Teilnehmen könnt, erfahrt ihr auf den jeweiligen Kanälen. Schaut
            also gerne vorbei, wir freuen uns über eure Teilnahme! Viel Glück!
          </Text>
          <SocialLinks />
          <Image src={dreiundzwanzigster} />
        </DisplayDoor>
        <DisplayDoor day={24} pb={0}>
          <Heading size="md"></Heading>
          Christmas Sale
          <Text>
            Nun sind wir schon am letzten Tag unseres Adventskalenders
            angekommen und öffnen Türchen Nummer 24! Als Überraschung erwartet
            euch vom 24.12.-26.12.2021 ein großartiger Christmas Sale bei uns im
            Online-Shop (Link), bei dem ihr nochmal richtig sparen könnt.
            Sicherlich ist für viele von euch was schönes dabei, schaut also
            gerne vorbei! Wir wünschen euch ein besinnliches Weihnachtsfest und
            wundervolle Tage mit euren liebsten.
          </Text>
          <Button
            as={Link}
            href="https://figuya.com/de/sales/weihnachts-sale-21"
          >
            Weihnachts Sale 2021
          </Button>
          <SocialLinks />
          <Image src={vierundzwanzigster} />
        </DisplayDoor>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
