import {
  Stack,
  IconButton,
  Image,
  Box,
  ButtonGroup,
  Flex,
  Heading,
  Text,
  Container,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faArrowDown,
  faArrowUp,
  faArrowToTop,
  faLock,
} from "@fortawesome/pro-duotone-svg-icons";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import useInView from "react-cool-inview";

const goToDoor = (day) =>
  document
    .getElementById(`door-${day}`)
    .scrollIntoView({ top: 0, left: 0, behavior: "smooth" });

const NavigationButtons = ({ day }) => (
  <ButtonGroup>
    <IconButton
      colorScheme="whiteAlpha"
      onClick={() => goToDoor(day + 1)}
      icon={<FontAwesomeIcon icon={faArrowDown} />}
    />
    <IconButton
      colorScheme="whiteAlpha"
      onClick={() => goToDoor(day - 1)}
      icon={<FontAwesomeIcon icon={faArrowUp} />}
    />
    <IconButton
      colorScheme="whiteAlpha"
      onClick={() => goToDoor(0)}
      icon={<FontAwesomeIcon icon={faArrowToTop} />}
    />
  </ButtonGroup>
);

const ClosedDoor = ({ day, inView, bg = `red.${7 - (day % 7)}00` }) => {
  return (
    <Flex
      as={motion.div}
      initial={{ scale: 0 }}
      animate={{
        rotate: inView ? 360 : 0,
        scale: inView ? 1 : 0,
        transition: { duration: 1, delay: 1 },
      }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      width="200px"
      height="200px"
      rounded="full"
      bg={bg}
      justifyContent="center"
      alignItems="center"
    >
      <FontAwesomeIcon icon={faLock} size="4x" />
    </Flex>
  );
};

const OpenDoor = ({ day, inView, children, bg, pb, color }) => {
  return (
    <Container
      shadow="md"
      zIndex={4}
      maxHeight="80vh"
      overflow="auto"
      p={[4, 8, 16]}
      m={[2, 0]}
      pb={pb}
      color={color}
      as={motion.div}
      initial={{ scale: 0 }}
      animate={{
        borderRadius: inView ? "12px" : "100%",
        scale: inView ? 1 : 0,
        transition: { duration: 1, delay: inView ? 1 : 0 },
      }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      bg={bg}
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={2}>
        <Heading>{day}. Dezember 2021</Heading>
        {children}
      </Stack>
    </Container>
  );
};

const DisplayDoor = ({
  day,
  children,
  bg = `red.${7 - (day % 7)}00`,
  color = "white",
  pb = 16,
}) => {
  const { observe, inView } = useInView({ threshold: 0.9 });
  const currentTime = dayjs();
  const targetDay = dayjs(`2021-12-${day}`);
  const dayAfterTarget = dayjs(`2021-12-${day + 1}`);
  const isLocked = currentTime.isBefore(targetDay);
  const isToday =
    currentTime.isAfter(targetDay) && currentTime.isBefore(dayAfterTarget);

  return (
    <Flex
      ref={observe}
      position="relative"
      fontSize={["xs", "sm", "md"]}
      justifyContent="center"
      alignItems="center"
      key={day}
      bg={`red.${9 - (day % 7)}00`}
      id={`door-${day}`}
      overflow="auto"
      height="100vh"
    >
      <Box position="absolute" top="30px" left="30px">
        <NavigationButtons day={day} />
      </Box>
      {isLocked ? (
        <ClosedDoor bg={bg} inView={inView} day={day} />
      ) : (
        <OpenDoor pb={pb} bg={bg} inView={inView} day={day} color={color}>
          {children}
        </OpenDoor>
      )}
      <Flex
        position="absolute"
        as={motion.div}
        initial={{ scale: 1 }}
        animate={{
          rotate: inView ? 0 : 360,
          scale: inView ? 0 : 1,
          transition: { duration: 1, delay: inView ? 0 : 1 },
        }}
        exit={{ width: 0, height: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
        width="200px"
        height="200px"
        rounded="full"
        bg={`red.${7 - (day % 7)}00`}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="xl">{day}</Text>
      </Flex>
    </Flex>
  );
};

export default DisplayDoor;
