import { Box, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { motion } from "framer-motion";

const goToDoor = (day) =>
  document
    .getElementById(`door-${day}`)
    .scrollIntoView({ top: 0, left: 0, behavior: "smooth" });

const LockedDoor = ({ day }) => (
  <Flex
    onClick={() => goToDoor(day)}
    color="white"
    alignItems="center"
    justifyContent="center"
    color="white"
    bg="blackAlpha.500"
    p={10}
    rounded="lg"
  >
    <Text fontSize="xl">{day}</Text>
  </Flex>
);

const TodayDoor = ({ day }) => {
  return (
    <Flex
      as={motion.div}
      bg="whiteAlpha.400"
      rounded="lg"
      whileHover={{
        scale: [1, 0.9, 1, 1.1, 1, 0.9, 1],
      }}
      onClick={() => goToDoor(day)}
      color="white"
      alignItems="center"
      justifyContent="center"
      p={10}
    >
      <Text fontSize="xl">{day}</Text>
    </Flex>
  );
};

const OpenDoor = ({ day }) => {
  return (
    <Flex
      as={motion.div}
      bg="whiteAlpha.100"
      rounded="lg"
      animate={{}}
      onClick={() => goToDoor(day)}
      color="white"
      alignItems="center"
      justifyContent="center"
      p={10}
    >
      <Text fontSize="xl">{day}</Text>
    </Flex>
  );
};

const Door = ({ day }) => {
  const currentTime = dayjs();
  const targetDay = dayjs(`2021-12-${day}`);
  const dayAfterTarget = dayjs(`2021-12-${day + 1}`);
  const isLocked = currentTime.isBefore(targetDay);
  const isToday =
    currentTime.isAfter(targetDay) && currentTime.isBefore(dayAfterTarget);
  if (isLocked) return <LockedDoor day={day} />;
  if (isToday) return <TodayDoor day={day} />;
  return <OpenDoor day={day} />;
};

export default Door;
