import React from "react";
import advent from "../../images/advent-final.png";
import {
  Container,
  Heading,
  Text,
  Image,
  Flex,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import Door from "./calender-item";
import useDimensions from "react-cool-dimensions";

const Doors = () => {
  const { observe, height } = useDimensions();
  return (
    <Box bg="#270004">
      <Box minHeight={[`${height}px`, `${height}px`, "100vh"]}>
        <Flex
          cursor="pointer"
          alignItems="center"
          justifyContent="center"
          position="relative"
          as="header"
        >
          <Image top="0" zIndex="1" width="100vw" src={advent} />
          <Box top="0" ref={observe} position="absolute" zIndex="2" id="door-0">
            <Container py={10}>
              <Box mb={2} color="white" p={16} bg="blackAlpha.400" rounded="lg">
                <Heading as="h1">Figuren Advent 2021</Heading>
                <Text fontSize="xl">Figuya wünscht ein frohes Fest!</Text>
              </Box>
              <SimpleGrid spacing={2} columns={[3, 4]}>
                <Door day={1} />
                <Door day={2} />
                <Door day={3} />
                <Door day={4} />
                <Door day={5} />
                <Door day={6} />
                <Door day={7} />
                <Door day={8} />
                <Door day={9} />
                <Door day={10} />
                <Door day={11} />
                <Door day={12} />
                <Door day={13} />
                <Door day={14} />
                <Door day={15} />
                <Door day={16} />
                <Door day={17} />
                <Door day={18} />
                <Door day={19} />
                <Door day={20} />
                <Door day={21} />
                <Door day={22} />
                <Door day={23} />
                <Door day={24} />
                <div id="door-25" />
              </SimpleGrid>
            </Container>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Doors;
